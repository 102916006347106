import React from "react"
import { Link } from "gatsby"
import "./assets/styles/_index.scss"
import GetGGFXImage from "../common/site/get-ggfx-image"
import noImg from "../../images/no-image.png"
import { getPropertyBaseURI } from "../TrackRecordPage/helper"

const TrackRecordCard = ({ trackClass, recordData }) => {
  const record = recordData.record

  const uri = getPropertyBaseURI(recordData.type)

  let processedImages = JSON.stringify({})
  if (record?.imagetransforms?.images_Transforms) {
    processedImages = record.imagetransforms.images_Transforms
  }

  return (
    <div className="track-record-card-wrapper">
      <div className={"track-slide " + trackClass}>
        <Link to={`${uri}${record.slug}-${record.id}/`}>
          <div className="track-img-zoom">
            <div className="track-slide-label d-flex align-items-center justify-content-center">
              {recordData.type}
            </div>
            {record.images && record.images[0] ? (
              <GetGGFXImage
                imagename={"property.images.detail"}
                imagesource={record.images[0]}
                fallbackalt={record.display_address}
                imagetransformresult={processedImages}
                id={record.id}
              />
            ) : (
              <img src={noImg} className="img-fluid" />
            )}

            <div className="track-slide-display-adderss-wrapper d-flex justify-content-md-between justify-content-end">
              <div className="track-slide-display-address d-md-flex align-items-center justify-content-center d-none">
                <span>{record.display_address}</span>
              </div>
              <div className="track-slide-link d-flex align-items-center justify-content-center">
                <i className="icon icon-track-slider-link-arrow"></i>
              </div>
            </div>
          </div>
          <div className="d-md-none d-block track-slide-display-address-sm">
            <span>{record.display_address}</span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default TrackRecordCard
